import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBView } from "mdbreact";
import React, { Component } from 'react';
import thanksgivingToGo from './images/SU_Thanksgiving_Dinner_ToGo.jpg';
import thanksgivingBakeSale from './images/SU_Thanksgiving_Bake_Sale.jpg';
import ourkitchen from './images/SU-Website_Home-page_Ad-Refresh_Back-of-Our-Kitchen_UPDATED_AUG 2023-V3 (2).png';
import diningGuide from './images/SU_DiningGuide_WebBanner_23_V6.jpg';
import discover_su from './images/discover_su.d25fecd6.jpg';
import './style.css';

class SlideShow extends Component {

    render() {
		let imgs = [null, 
            thanksgivingToGo,
            thanksgivingBakeSale,
            diningGuide,
            ourkitchen, 
            discover_su
        ];
		let urls = [null, 
            'https://union.arizona.edu/thanksgiving/',
            'https://union.arizona.edu/thanksgiving/#bakesale',
            'https://mealplans.arizona.edu/resources/23_Dining_Guide_Digital_V14.pdf',
            'https://union.arizona.edu/dining/dining-by-district/', 
            'https://union.arizona.edu/involvement/'
        ];
        let alts = [null, 
            'Thanksgiving Dinner To-Go',
            'Thanksgiving Bake Sale',
            'Dining guide', 
            'Dining by District',
            'Discover the union'
        ];
 
        const sunsetDate = new Date('2023-11-22');
        const currentDate = new Date();

        if(currentDate >= sunsetDate) {
            imgs = [null, ...imgs.slice(3)];
            urls = [null, ...urls.slice(3)];
            alts = [null, ...alts.slice(3)];
        }

        return(
            <MDBContainer className="p-0">
                <MDBCarousel activeItem={1} length={imgs.length-1} showControls={true} showIndicators={true} interval={6000} className="z-depth-1">
                    <MDBCarouselInner>

                        {
                            imgs.map((img, index) => {
                                if (img != null){
                                    return (
                                        <MDBCarouselItem itemId={index}>
                                            <MDBView>
                                                <a href={urls[index]}><img className="d-block w-100" src={img} alt={alts[index]} /></a>
                                                {/* <MDBMask overlay="black-light" /> */}
                                            </MDBView>
                                            <MDBCarouselCaption>
                                                {/* <h3 className="h3-responsive">Light mask</h3>
                                                <p>First text</p> */}
                                            </MDBCarouselCaption>
                                        </MDBCarouselItem>
                                    )
                                }
                                else {
                                    return null
                                }
                            })
                        }

                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBContainer>
        );
    }
}

export default SlideShow;